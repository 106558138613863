import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import KV from '../assets/images/top/TOP.jpg'
import OGP from '../assets/images/OGP.png'



const HomeIndex = () => {
  const siteTitle = '小野寺亮 | Onodera Ryo'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content="小野寺 亮 / Ryo Onodera | 1993年、福島県生まれ。東京在住。カタログ、広告、アーティスト写真など幅広く活動。映画監督として映画、広告、MVなども製作している。主な個展に0.1cm(Tokyo,2017)、ZOTOKA(Tokyo,2017)など。" />
        <meta name="keywords" content="小野寺亮, onodera ryo, ryo onodera, 写真家, photographer, カメラマン, " />
        <meta name="image" content="https://66.media.tumblr.com/9951914de69ea8b06b44179fb2b971ff/b74574303e11823b-af/s1280x1920/4ee90e8442bcb376a3fc264ddb9e4bb0b7e49c4c.png" />
        <meta property="og:title" content="小野寺亮 | ウェブサイト" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://66.media.tumblr.com/9951914de69ea8b06b44179fb2b971ff/b74574303e11823b-af/s1280x1920/4ee90e8442bcb376a3fc264ddb9e4bb0b7e49c4c.png" />
        <meta property="og:url" content="https://onoderaryo.com/" />
        <meta property="og:site_name" content="小野寺亮 ウェブサイト" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ryooo_onodera" />
        <meta name="twitter:image" content="https://66.media.tumblr.com/9951914de69ea8b06b44179fb2b971ff/b74574303e11823b-af/s1280x1920/4ee90e8442bcb376a3fc264ddb9e4bb0b7e49c4c.png" />
      </Helmet>
      <div id="main">
        <div class="KV">
          <img src={KV} alt="KV"/>
        </div>
      </div>
    </Layout>
  )
}

export default HomeIndex
